import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "roofSlopeSelect",
    "rafterSpacingField",
    "conduitMountsSelect",
    "qmTileReplacementFlashingForTileHooksYes",
    "qmTileReplacementFlashingForTileHooksNo",
    "qmTileDeckFlashingYes",
    "qmTileDeckFlashingNo",
    "qmConduitMountsForTileSelect",
    "qmConduitMountPenetrationFlashingForTileYes",
    "qmConduitMountPenetrationFlashingForTileNo",
    "heightField",
  ];

  connect() {
    this.defaultData = JSON.parse(this.element.dataset.projectDetail);
    this.#toggleTileDeckFlashingInfoPopup();
  }

  applyDefaults(event) {
    event.preventDefault();

    if (this.hasHeightFieldTarget) {
      this.heightFieldTarget.value = this.defaultData.buildingHeight;
    }

    if (this.hasRoofSlopeSelectTarget) {
      this.roofSlopeSelectTarget.value = this.defaultData.roofSlope;
    }

    if (this.hasRafterSpacingFieldTarget) {
      this.rafterSpacingFieldTarget.value = this.defaultData.defaultRafterSpacing;
    }

    if (this.hasConduitMountsSelectTarget) {
      this.conduitMountsSelectTarget.value = this.defaultData.conduitMounts;
    }

    this.yesNoRadioDefaultCheck("qmTileReplacementFlashingForTileHooks");
    this.yesNoRadioDefaultCheck("qmTileDeckFlashing");
    if (this.defaultData.qmConduitMountsForTile !== "") {
      this.qmConduitMountsForTileSelectTarget.value = this.defaultData.qmConduitMountsForTile;
    }

    if (this.hasRoofSlopeSelectTarget) {
      // Trigger dirty tracking to show the save button
      const changeEvent = new CustomEvent("change", { bubbles: true });
      this.roofSlopeSelectTarget.dispatchEvent(changeEvent);
    }
  }

  yesNoRadioDefaultCheck(baseName) {
    if (!this.hasTarget(`${baseName}YesTarget`)) return;

    const defaultValue = this.defaultData[baseName];
    const yesRadio = this[`${baseName}YesTarget`];
    const noRadio = this[`${baseName}NoTarget`];

    if (defaultValue) {
      yesRadio.checked = true;
    } else {
      noRadio.checked = true;
    }
  }

  hasTarget(name) {
    return this[`has${name.charAt(0).toUpperCase() + name.slice(1)}`];
  }

  #toggleTileDeckFlashingInfoPopup() {
    const roofAttachment = this.defaultData.roofAttachment;

    if (this.hasQmTileDeckFlashingYesTarget) {
      const dialog = document.querySelector("[data-dialog-identifier=info-popup-pr-tile-deck-flashing]");
      if (roofAttachment === "ko_f") {
        dialog.querySelector("[data-tile-hook-deck-flashing=false]").style.display = "block";
        dialog.querySelector("[data-tile-hook-deck-flashing=true]").style.display = "none";
      } else {
        dialog.querySelector("[data-tile-hook-deck-flashing=true]").style.display = "block";
        dialog.querySelector("[data-tile-hook-deck-flashing=false]").style.display = "none";
      }
    }
  }
}
