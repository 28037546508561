import React from "react";
import PropTypes from "prop-types";
import { panelPropTypeShape, endClampPropTypeShape } from "../helper";

import PanelSelectBase from "./PanelSelectBase";

class IronRidgePanelSelect extends PanelSelectBase {
  static propTypes = {
    projectType: PropTypes.string.isRequired,
    loadingPanels: PropTypes.bool.isRequired,
    panels: PropTypes.arrayOf(panelPropTypeShape),
    selectPanel: PropTypes.func.isRequired,
    selectedPanelId: PropTypes.number,
    endClamp: endClampPropTypeShape,
    onlyShowUlListedAndCompatible: PropTypes.bool.isRequired,
    onlyShowBxCompatible: PropTypes.bool.isRequired,
    hidePanelPickerDialog: PropTypes.func.isRequired,
    railPlatform: PropTypes.string.isRequired,
    efoAvailable: PropTypes.bool.isRequired,
    stopperAvailable: PropTypes.bool.isRequired,
    eclipseAvailable: PropTypes.bool.isRequired,
    createdBefore42mmStopperSleeveUfoCutoffDate: PropTypes.bool.isRequired,
  };

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    const { panels, onlyShowUlListedAndCompatible, onlyShowBxCompatible } = this.props;

    if (JSON.stringify(prevProps.panels) !== JSON.stringify(panels)) {
      this.searchTermChange("");
    }

    if (
      prevProps.onlyShowUlListedAndCompatible !== onlyShowUlListedAndCompatible ||
      prevProps.onlyShowBxCompatible !== onlyShowBxCompatible
    ) {
      this.setFilteredPanels();
    }
  }

  setFilteredPanels() {
    const { panels, onlyShowUlListedAndCompatible, onlyShowBxCompatible } = this.props;
    const { searchTerm } = this.state;

    const onlyShowCheckbox = this.isBxProject ? onlyShowBxCompatible : onlyShowUlListedAndCompatible;

    let filteredPanels;
    if (searchTerm.length === 0 && !onlyShowCheckbox) {
      filteredPanels = this.selectedPanelAndNonRetiredPanels;
    } else {
      filteredPanels = panels.filter(this.shouldIncludePanelInFilteredList);
    }

    this.setState({ filteredPanels });
  }

  shouldIncludePanelInFilteredList = (panel) => {
    const { onlyShowUlListedAndCompatible, onlyShowBxCompatible, persistedSelectedPanel } = this.props;
    const { searchTerm } = this.state;

    if (searchTerm.length > 0) {
      const includesSearchTerm = panel.name.toLowerCase().includes(searchTerm.toLowerCase());
      if (!includesSearchTerm) return false;
    }

    if (panel.retired && (!persistedSelectedPanel || panel.id !== persistedSelectedPanel.id)) return false;

    if (this.isBxProject && onlyShowBxCompatible) {
      return !this.bxDisabled(panel) && this.isUlApproved(panel);
    } else if (onlyShowUlListedAndCompatible) {
      if (!this.isUlApproved(panel)) return false;
      if (this.isPrFrGbProjectAndNotLengthCompatible(panel)) return false;

      if (this.panelIsDisabled(panel)) return false;
    }

    return true;
  };

  isValidSelectionIndex = (selectionIndex) => {
    const { filteredPanels } = this.state;
    const panel = filteredPanels[selectionIndex];
    return !this.panelIsDisabled(panel);
  };

  isUlApproved(panel) {
    return panel.ul_listed_status === "approved";
  }

  panelIsDisabled(panel) {
    const { enableAllPanels, railPlatform } = this.props;

    if (enableAllPanels) return false;

    if (this.isBxProject) {
      return this.bxDisabled(panel);
    } else if (this.isPrFrGbProjectAndNotLengthCompatible(panel)) {
      return true;
    }

    if (railPlatform === "aire") {
      return this.aireDisabled(panel);
    } else {
      return this.xrDisabled(panel);
    }
  }

  bxDisabled(panel) {
    return !panel.bx_thickness_compatible || !panel.bx_width_compatible;
  }

  aireDisabled(panel) {
    if (!panel.aire_lock_mid_compatible) return true;

    if (this.props.endClamp === "lock_stealth") {
      return !panel.aire_lock_stealth_usable;
    } else {
      return !panel.aire_lock_end_compatible;
    }
  }

  xrDisabled(panel) {
    if (!panel.ufo_compatible) return true;

    if (this.props.endClamp === "stopper") {
      if (panel.thickness_mm === 42) {
        return !this.props.createdBefore42mmStopperSleeveUfoCutoffDate;
      } else {
        return !panel.ufo_stopper_compatible;
      }
    } else if (this.props.endClamp === "efo") {
      return panel.efo_thickness_compatibility !== "compatible";
    } else {
      return !panel.camo_usable;
    }
  }

  panelClassNames(panel, index) {
    const { currentSelectionIndex } = this.state;

    const classNames = ["pp__panels__panel"];
    if (currentSelectionIndex === index) {
      classNames.push("pp__panels__panel--selected");
    }
    if (this.panelIsDisabled(panel)) {
      classNames.push("pp__panels__panel--disabled");
    }
    return classNames;
  }

  get allFilteredPanelsDisabled() {
    const { filteredPanels } = this.state;
    return filteredPanels.every((p) => this.panelIsDisabled(p));
  }

  renderPanelSelectHeadingMarker() {
    return "";
  }
}

export default IronRidgePanelSelect;
