import React, { Component } from "react";
import SearchField from "../SearchField";
import NoMatchingRecords from "../NoMatchingRecords";
import { confirmDialog } from "../../../controllers/components/ir_dialog/helper";
import { detailsLinkParams } from "../helper";

class PanelSelectBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: "",
      currentSelectionIndex: undefined,
      filteredPanels: [],
    };
  }

  componentDidMount() {
    this.setFilteredPanels();
  }

  get isPrProject() {
    return this.props.projectType === "PR4";
  }

  get isFrProject() {
    return this.props.projectType === "FR4";
  }
  get isGbProject() {
    return this.props.projectType === "GB4";
  }

  get isBxProject() {
    return this.props.projectType === "BX";
  }

  isPrProjectAndNotLengthCompatible(panel) {
    return this.isPrProject && !panel.pr_length_compatible;
  }

  isFrProjectAndNotLengthCompatible(panel) {
    return this.isFrProject && !panel.fr_length_compatible;
  }

  isGbProjectAndNotLengthCompatible(panel) {
    return this.isGbProject && !panel.gb_length_compatible;
  }

  isBxProjectAndNotLengthCompatible(panel) {
    return this.isBxProject && !panel.bx_length_compatible;
  }

  isPrFrGbProjectAndNotLengthCompatible(panel) {
    return (
      this.isPrProjectAndNotLengthCompatible(panel) ||
      this.isFrProjectAndNotLengthCompatible(panel) ||
      this.isGbProjectAndNotLengthCompatible(panel)
    );
  }

  get selectedPanelAndNonRetiredPanels() {
    const { panels, persistedSelectedPanel } = this.props;
    return panels.filter((p) => (persistedSelectedPanel && p.id === persistedSelectedPanel.id) || !p.retired);
  }

  selectCollectionItemFromCurrentSelectionIndex = () => {
    const { currentSelectionIndex, filteredPanels } = this.state;
    const index = currentSelectionIndex || 0;

    const panel = filteredPanels[index];
    if (panel === undefined) return;

    this.selectPanel(panel.id);
  };

  searchTermChange = (searchTerm) => {
    this.setState({ searchTerm }, () => this.setFilteredPanels());
  };

  shouldIncludePanelInFilteredList = (panel) => {
    const { searchTerm } = this.state;

    if (searchTerm.length > 0) {
      const includesSearchTerm = panel.name.toLowerCase().includes(searchTerm.toLowerCase());
      if (!includesSearchTerm) return false;
    }

    return true;
  };

  setCurrentSelectionIndex = (currentSelectionIndex) => {
    this.setState({ currentSelectionIndex });
  };

  get selectedPanel() {
    const { selectedPanelId } = this.props;
    return this.findPanel(selectedPanelId);
  }

  findPanel(id) {
    const { panels } = this.props;
    return panels.find((p) => p.id === id);
  }

  selectPanelComplete(panel) {
    const { panels, selectPanel: propsSelectPanel } = this.props;
    propsSelectPanel(panel);

    this.setState({
      searchTerm: "",
      currentSelectionIndex: undefined,
    });
  }

  selectPanel(id) {
    const { hidePanelPickerDialog } = this.props;

    const panel = this.findPanel(id);
    if (this.panelIsDisabled(panel)) {
      alert(`The panel ${panel.name} can not be selected.  It is incompatible.`);
      return;
    }

    if (!panel.retired && this.isPersistedSelectedPanelRetired) {
      hidePanelPickerDialog();

      confirmDialog(this.confirmMovingAwayFromRetiredPanelMessage, (confirmed) => {
        if (confirmed) this.selectPanelComplete(panel);
      });
    } else if (this.isPersistedSelectedPanelCustomAndOwnedByAnotherUser(panel)) {
      hidePanelPickerDialog();

      confirmDialog(this.confirmMovingAwayFromCustomPanelThatUserDoesNotOwn, (confirmed) => {
        if (confirmed) this.selectPanelComplete(panel);
      });
    } else {
      this.selectPanelComplete(panel);
    }
  }

  get confirmMovingAwayFromRetiredPanelMessage() {
    const { persistedSelectedPanel } = this.props;
    const message = `
        The panel you currently have assigned to this project (${persistedSelectedPanel.name}) is retired.
        If you change to another panel and save, you will no longer be able to assign this panel to this project.
      `;
    return message;
  }

  get confirmMovingAwayFromCustomPanelThatUserDoesNotOwn() {
    const { projectPanelUser, persistedSelectedPanel } = this.props;
    const message = `
        The panel you currently have assigned to this project (${persistedSelectedPanel.name}) is owned by another user,
        ${projectPanelUser.first_name} ${projectPanelUser.last_name}. If you change to another panel and save, you will
        no longer be able to assign this panel to this project.
      `;
    return message;
  }

  get isPersistedSelectedPanelRetired() {
    const { persistedSelectedPanel } = this.props;
    if (persistedSelectedPanel === undefined) return false;
    return persistedSelectedPanel.retired;
  }

  isPersistedSelectedPanelCustomAndOwnedByAnotherUser(panel) {
    const { currentUser, persistedSelectedPanel } = this.props;
    return (
      persistedSelectedPanel && // there is a persisted selected panel
      persistedSelectedPanel.user_id && // the persisted selected panel is a custom panel
      panel.user_id !== persistedSelectedPanel.user_id && // the persisted selected panel does not belong to the same user
      persistedSelectedPanel.user_id !== currentUser.id // the persisted selected panel does not belong the current user
    );
  }

  renderPanel(panel, index) {
    return (
      <div
        className={this.panelClassNames(panel, index).join(" ")}
        key={panel.id}
        onClick={() => this.selectPanel(panel.id)}
      >
        <div className="pp__panel__name" data-testid="panel-name">
          {panel.name}
        </div>
        <div className="pp__panel__ul_listed">{panel.user_id === null ? panel.ul_listed_status : "NA"}</div>
        {this.renderRailPlatformSpecificCompatibilityCells(panel)}
        {this.renderBxCompatibilityCells(panel)}
        {this.renderContourCompatibilityCell(panel)}
        <div className="pp__panel__details">{this.renderPanelDetailsButton(panel)}</div>
      </div>
    );
  }

  renderPanelDetailsButton(panel) {
    const { projectType, projectId, railPlatform, efoAvailable, stopperAvailable, eclipseAvailable } = this.props;

    return (
      <button
        data-action="click->components--ir-dialog--turbo-frame-details#clickToOpen"
        className="ir-btn ir-btn--gray ir-btn--icon-prepend ir-btn--small"
        {...detailsLinkParams(
          panel,
          projectType,
          projectId,
          railPlatform,
          efoAvailable,
          stopperAvailable,
          eclipseAvailable,
        )}
      >
        <span className="ir-btn__icon">
          <i className="fas fa-magnifying-glass" />
        </span>
        <span className="ir-btn__text">Details</span>
      </button>
    );
  }

  renderRailPlatformSpecificCompatibilityCells(panel) {
    if (this.props.projectType === "BX") return;

    if (this.props.railPlatform === "xr") {
      return this.renderXrPanelCompatibilityCells(panel);
    } else if (this.props.railPlatform === "aire") {
      return this.renderAirePanelCompatibilityCells(panel);
    }
  }

  renderXrPanelCompatibilityCells(panel) {
    return (
      <>
        <div className="pp__panel__ufo">{this.compatibilityIcon(panel, "ufo_thickness_compatibility")}</div>
        {this.props.stopperAvailable && (
          <div className="pp__panel__ufo_stopper">
            {this.compatibilityIcon(panel, "ufo_stopper_thickness_compatibility")}
          </div>
        )}
        {this.props.efoAvailable && (
          <div className="pp__panel__efo">{this.compatibilityIcon(panel, "efo_thickness_compatibility")}</div>
        )}
        <div className="pp__panel__camo">{this.renderSidesCompatibilityIcons(panel, "camo")}</div>
        {this.props.eclipseAvailable && (
          <div className="pp__panel__eclipse">{this.renderSidesCompatibilityIcons(panel, "eclipse")}</div>
        )}
      </>
    );
  }

  renderAirePanelCompatibilityCells(panel) {
    return (
      <>
        <div className="pp__panel__aire_mid">
          {this.compatibilityIcon(panel, "aire_lock_mid_thickness_compatibility")}
        </div>
        <div className="pp__panel__aire_end">
          {this.compatibilityIcon(panel, "aire_lock_end_thickness_compatibility")}
        </div>
        <div className="pp__panel__aire_stealth">{this.renderSidesCompatibilityIcons(panel, "aire_lock_stealth")}</div>
      </>
    );
  }

  renderSidesCompatibilityIcons(panel, part) {
    const longIcon = this.compatibilityIcon(panel, `${part}_flange_compatibility_long_side`, "L");
    const shortIcon = this.compatibilityIcon(panel, `${part}_flange_compatibility_short_side`, "S");

    const longCompatibility = panel[`${part}_flange_compatibility_long_side`];
    const shortCompatibility = panel[`${part}_flange_compatibility_short_side`];

    const longUsability = this.usableText(longCompatibility);
    const shortUsability = this.usableText(shortCompatibility);

    const toolTipContent = `Long side: ${longCompatibility.replace(
      "_",
      " ",
    )} (${longUsability})<br /> Short side: ${shortCompatibility.replace("_", " ")} (${shortUsability})`;

    return (
      <span
        className="compat_container text-nowrap bg-gray-100 p-2 rounded"
        data-controller="components--ir-tooltip"
        data-components--ir-tooltip-content-value={toolTipContent}
      >
        <span className="me-3">{longIcon}</span>
        {shortIcon}
      </span>
    );
  }

  usableText(compatibility) {
    if (compatibility === "compatible" || compatibility === "soft_compatible") {
      return "usable";
    } else if (compatibility === "incompatible" || compatibility === "unknown") {
      return "not usable";
    }
  }

  renderBxCompatibilityCells(panel) {
    if (this.props.projectType !== "BX") return;

    return (
      <>
        <div className="pp__panel__bx_width">{this.compatibilityIcon(panel, "bx_width_compatibility")}</div>
        <div className="pp__panel__bx_thickness">{this.compatibilityIcon(panel, "bx_thickness_compatibility")}</div>
      </>
    );
  }

  renderContourCompatibilityCell(panel) {
    if (!this.showContour) return;

    return <div className="pp__panel__contour">{this.renderSidesCompatibilityIcons(panel, "contour")}</div>;
  }

  get showContour() {
    return !["BX", "FR4", "GB4", "FR", "GB"].includes(this.props.projectType);
  }

  compatibilityIcon(panel, compatibility_property, prefix = "") {
    const compat = panel[compatibility_property];
    let prefixSpan = "";
    if (prefix) {
      prefixSpan = <span className="pe-1 icon_prefix">{prefix}:</span>;
    }

    if (compat === "compatible") {
      return (
        <span className="text-green-500">
          {prefixSpan}
          <i className="fas fa-check" />
        </span>
      );
    } else if (compat === "incompatible") {
      return (
        <span className="text-red-600">
          {prefixSpan}
          <i className="fas fa-times" />
        </span>
      );
    } else if (compat === "soft_compatible") {
      return (
        <span className="text-yellow-600">
          {prefixSpan}
          <i className="fas fa-question" />
        </span>
      );
    } else if (compat === "unknown") {
      return (
        <span className="text-red-600">
          {prefixSpan}
          <i className="fas fa-question" />
        </span>
      );
    }
  }

  renderPanels() {
    const { filteredPanels } = this.state;
    const { loadingPanels } = this.props;

    if (loadingPanels) {
      return (
        <div className="pp__panels__loading">
          <div className="pp__panels__loading__spinner-icon" />
          <div className="pp__panels__loading__text" data-testid="loading-panels">
            Loading
          </div>
        </div>
      );
    }

    if (filteredPanels.length === 0) {
      return <NoMatchingRecords />;
    }

    return (
      <>
        <div className="pp__panels__header">
          <div className="pp__panel__name">Name</div>
          <div className="pp__panel__ul_listed">UL Listed</div>
          {this.renderRailsPlatformSpecificHeaders()}
          {this.renderBxHeaders()}
          {this.renderContourHeader()}
        </div>
        <div className="pp__panels__container">{filteredPanels.map((p, i) => this.renderPanel(p, i))}</div>
      </>
    );
  }

  renderRailsPlatformSpecificHeaders() {
    if (this.props.projectType === "BX") return;

    if (this.props.railPlatform === "xr") {
      return (
        <>
          <div className="pp__panel__ufo">UFO</div>
          {this.props.stopperAvailable && <div className="pp__panel__ufo_stopper">Stopper</div>}
          {this.props.efoAvailable && <div className="pp__panel__efo">EFO</div>}
          <div className="pp__panel__camo">CAMO</div>
          {this.props.eclipseAvailable && <div className="pp__panel__eclipse">Eclipse</div>}
        </>
      );
    } else if (this.props.railPlatform === "aire") {
      return (
        <>
          <div className="pp__panel__aire_mid">Aire Mid</div>
          <div className="pp__panel__aire_end">Aire End</div>
          <div className="pp__panel__aire_stealth">Aire Stealth</div>
        </>
      );
    }
  }

  renderBxHeaders() {
    if (this.props.projectType !== "BX") return;

    return (
      <>
        <div className="pp__panel__bx_width">Width</div>
        <div className="pp__panel__bx_thickness">Thickness</div>
      </>
    );
  }

  renderContourHeader() {
    if (!this.showContour) return;

    return <div className="pp__panel__contour">Contour</div>;
  }

  renderPanelSelectHeading() {
    const { selectedPanel } = this;

    return (
      <>
        {selectedPanel && (
          <div style={{ paddingBottom: "1.5rem" }}>
            <span>Panel:</span> <b className="pp__panel__selected-panel-name">{selectedPanel.name}</b>
          </div>
        )}
        <div>
          <h4 className="pp__select-title" data-testid="select-panel-title">
            Select a {selectedPanel && <span>different</span>} panel
            {this.renderPanelSelectHeadingMarker()}
          </h4>
        </div>
      </>
    );
  }

  render() {
    const { searchTerm, currentSelectionIndex, filteredPanels } = this.state;

    return (
      <div className="ir-form">
        {this.renderPanelSelectHeading()}
        <SearchField
          id="panel-search"
          searchTerm={searchTerm}
          searchTermChange={this.searchTermChange}
          currentSelectionIndex={currentSelectionIndex}
          setCurrentSelectionIndex={this.setCurrentSelectionIndex}
          collectionCount={filteredPanels.length}
          selectCollectionItemFromCurrentSelectionIndex={this.selectCollectionItemFromCurrentSelectionIndex}
          isValidSelectionIndex={this.isValidSelectionIndex}
          allSelectionsInvalid={this.allFilteredPanelsDisabled}
        />
        {this.renderPanels()}
      </div>
    );
  }
}

export default PanelSelectBase;
