import React from "react";
import PropTypes from "prop-types";
import { customPanelPropTypeShape, endClampPropTypeShape } from "../helper";
import PanelSelectBase from "./PanelSelectBase";

class CustomPanelsSelect extends PanelSelectBase {
  static propTypes = {
    projectType: PropTypes.string.isRequired,
    loadingPanels: PropTypes.bool.isRequired,
    panels: PropTypes.arrayOf(customPanelPropTypeShape),
    selectPanel: PropTypes.func.isRequired,
    selectedPanelId: PropTypes.number,
    endClamp: endClampPropTypeShape,
    onlyShowUlListedAndCompatible: PropTypes.bool.isRequired,
    hidePanelPickerDialog: PropTypes.func.isRequired,
    railPlatform: PropTypes.string.isRequired,
    efoAvailable: PropTypes.bool.isRequired,
    stopperAvailable: PropTypes.bool.isRequired,
    eclipseAvailable: PropTypes.bool.isRequired,
    createdBefore42mmStopperSleeveUfoCutoffDate: PropTypes.bool.isRequired,
  };

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    const { panels } = this.props;

    if (JSON.stringify(prevProps.panels) !== JSON.stringify(panels)) {
      this.searchTermChange("");
    }
  }

  setFilteredPanels() {
    const { panels } = this.props;
    const { searchTerm } = this.state;

    let filteredPanels;
    if (searchTerm.length === 0 && !this.props.onlyShowUlListedAndCompatible) {
      filteredPanels = panels;
    } else {
      filteredPanels = panels.filter(this.shouldIncludePanelInFilteredList);
    }

    this.setState({ filteredPanels });
  }

  shouldIncludePanelInFilteredList = (panel) => {
    const { searchTerm } = this.state;
    const { onlyShowUlListedAndCompatible, projectType } = this.props;

    if (searchTerm.length > 0) {
      const includesSearchTerm = panel.name.toLowerCase().includes(searchTerm.toLowerCase());
      if (!includesSearchTerm) return false;
    }

    if (onlyShowUlListedAndCompatible && projectType != "BX" && this.isPrFrGbProjectAndNotLengthCompatible(panel)) {
      return false;
    }

    return true;
  };

  isUlApproved(panel) {
    return panel.ul_listed_status === "approved";
  }

  panelIsDisabled(panel) {
    const { projectType } = this.props;

    if (projectType === "BX") return false;

    if (this.isPrFrGbProjectAndNotLengthCompatible(panel)) {
      return true;
    } else {
      if (this.props.endClamp === "stopper" && panel.thickness_mm === 42) {
        return !this.props.createdBefore42mmStopperSleeveUfoCutoffDate;
      } else {
        return false;
      }
    }
  }

  panelClassNames(panel, index) {
    const { currentSelectionIndex } = this.state;
    const { projectType } = this.props;

    const classNames = ["pp__panels__panel"];
    if (currentSelectionIndex === index) {
      classNames.push("pp__panels__panel--selected");
    }

    if ((projectType != "BX" && this.isPrFrGbProjectAndNotLengthCompatible(panel)) || this.panelIsDisabled(panel)) {
      classNames.push("pp__panels__panel--disabled");
    }

    return classNames;
  }

  isValidSelectionIndex = (_selectionIndex) => {
    return true;
  };

  get allFilteredPanelsDisabled() {
    return false;
  }

  renderPanelSelectHeadingMarker() {
    return (
      <div className="pp__select-title__marker-right">
        <a href="/my_custom_panels">
          manage list <i className="fas fa-arrow-right"></i>
        </a>
      </div>
    );
  }
}

export default CustomPanelsSelect;
